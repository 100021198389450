export const LoginApiKey = () => {

    return {
        ClientId: 'webportal',
        ExcelClientId :'excel-client',
        GrantType: 'password',
    }
}

export const UserRoleIds = () => {

    return {
        UserRoleId: '3_ROLE_RA',
        SuperAdmin: '1_ROLE_SA',
        CompanyAdmin: '2_ROLE_CA',
        CollectionAdmin: '3_ROLE_RA',
        CollectionUser: '4_ROLE_RU',
    }
}

export const MenuOptionId = () => {

    return {
        ChangePassword: 1,
        Logout: 2,
    }
}

export const RandomPassword = () => {
    return {
        allowedChars: "abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890",
        passwordLength: 6
    }
}

export const CollectionFilterId = () => {
    return {
        groupName: "groupName",
        connection: "connection",
        // category: "category",
        collectionAsSource: 'collectionAsSource'
    }
}

export const CollectionFilterPropertyName = () => {
    return {
        groupName: "groupName",
        connection: "name"
        // category: "categoryName"
    }
}

export const ParameterType = () => {
    return {
        Fixed: "Fixed",
        FixedHidden: "Fixed Hidden",
        UserInput: "User Input",
        UserDefinedVariable: "User Defined Variable",
        SystemDefinedVariable: "System Defined Variable"
    }
}

export const ParameterTypeValue = () => {
    return {
        Fixed: "Fixed",
        FixedHidden: "Fixed Hidden",
        UserInput: "User Input",
        UserDefinedVariable: "UDV",
        SystemDefinedVariable: "SDV"
    }
}

export const DataTypeId = () => {
    return {
        Number: 1,
        Currency: 2,
        Date: 3,
        DateTime: 4,
        List: 5,
        String: 6,
        Boolean: 7,
        DynamicList: 8,
    }
}

export const FeatureType = () =>{
    return {
        CONNECTION_LIMIT : "CONNECTION_LIMIT",
        COLLECTION_LIMIT : "COLLECTION_LIMIT",
        TRANSFER_LIMIT : "TRANSFER_LIMIT",
        COLLECTION_USERS_LIMIT : "COLLECTION_USERS_LIMIT"
    }
}

export const LicenseTypeUIds = () => {
    return{
        FREE : "AS.FREE.1",
        BEGINNER: "AS.BEGN.2",
        PROFESSIONAL: "AS.PROF.3",
        SMALL_BUSINESS: "AS.MIDB.4"
    }
}

export const DataBaseDataType = () => {
    return{
        String : "String",
        Number: "Number",
        Int : "Int32",
        Long: "Int64",
        DateTime : "DateTime",
        Date: "Date",
        Currency : "Currency",
        Byte: "Byte",
    }
}

export const SysCollectionTypeId = () => {
    return {
        SQL_SERVER: 3,
        REST: 7,
    }
}

export const FilterConditions = () => {
    return {
        BETWEEN : "BETWEEN",
        HasProperty : "HAS_PROPERTY",
        NotHasProperty: "NOT_HAS_PROPERTY"
    }
}

export const DefaultDisplayFormat = () => {
    return {
        General : "General",
    }
}

export const SDVConstants = () => {
    return {
        Today : "@Today",
        Yesterday : "@Yesterday",
        Tomorrow : "@Tomorrow",
        StartDayOfThisWeek : "@StartDayOfThisWeek",
        EndDayOfTheWeek : "@EndDayOfTheWeek",
        PreviousBusinessDay : "@PreviousBusinessDay",
        EndOfPreviousMonth : "@EndOfPreviousMonth",
        EndOfPreviousQuarter : "@EndOfPreviousQuarter",
        EndOfLastYear : "@EndOfLastYear",
        StartOfThisMonth : "@StartOfThisMonth",
        StartOfThisQuarter : "@StartOfThisQuarter",
        StartOfThisYear : "@StartOfThisYear",
        EndOfThisYear : "@EndOfThisYear",
        EndOfThisMonth : "@EndOfThisMonth",
        EndOfThisQuarter : "@EndOfThisQuarter"
    }
}
export const DateFormats = () => {
    return{
        Y_M_D : "YYYY-MM-DD"
    }
}



export const Routes = () =>{
    return  [
        { path: '/variables', userRoleIds: ['2_ROLE_CA','3_ROLE_RA'] },
        { path: '/connections/add-connection',  userRoleIds: ['2_ROLE_CA'] },
        { path: '/connections/edit-connection',  userRoleIds: ['2_ROLE_CA'] },
        { path: '/book-an-expert', userRoleIds: ['2_ROLE_CA'] },
        { path: '/update-checkout',  userRoleIds: ['2_ROLE_CA'] },
        { path: '/users/user-permission',  userRoleIds: ['2_ROLE_CA'] },
        { path: '/data-collections/add-new-data-collection',  userRoleIds: ['2_ROLE_CA','3_ROLE_RA'] },
        { path: '/data-collections/update-collection',  userRoleIds: ['2_ROLE_CA','3_ROLE_RA'] },
        { path: '/data-collections/user-permissions',  userRoleIds: ['2_ROLE_CA'] },
      ];
}

export const WebPages = () =>{
    return {
        Variables : 'variables',
        AddCollection : 'add-new-data-collection',
        UpdateCollection : 'update-collection',
        AddConnection : 'add-connection',
        EditConnection : 'edit-connection',
        BookExpert: 'book-an-expert',
        UpdateCheckout: 'update-checkout'
    }
}

export const AppEnum = (function states() {

    return {
        LoginApiKey: LoginApiKey(),
        UserRoleIds: UserRoleIds(),
        MenuOptionId: MenuOptionId(),
        RandomPassword: RandomPassword(),
        CollectionFilterId: CollectionFilterId(),
        ParameterType: ParameterType(),
        ParameterTypeValue: ParameterTypeValue(),
        DataTypeId: DataTypeId(),
        CollectionFilterPropertyName: CollectionFilterPropertyName(),
        FeatureType: FeatureType(),
        LicenseTypeUIds: LicenseTypeUIds(),
        DataBaseDataType: DataBaseDataType(),
        SysCollectionTypeId: SysCollectionTypeId(),

        DefaultDisplayFormat: DefaultDisplayFormat(),
        FilterConditions: FilterConditions(),
        DateFormats: DateFormats(),
        SDVConstants: SDVConstants(),
        Routes: Routes(),
        WebPages: WebPages()

    }
})()