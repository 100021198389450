import React, { Fragment } from "react";
import "./VariableList.scss";
import "./../../user-permission/user-permission.scss"

import { Autocomplete, Checkbox, Paper, Stack, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../../../styles/_exports.module.scss";
import { ASButton } from "../../../shared/as-button/as-button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { ASTextField } from "../../../shared/as-text-fields";
import { selectedMenuItemBackgroundColor, StyledAutocompletePopper } from "../../data-collections/collection-parameters/collection-parameters";
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";
import _ from "lodash";

const ASheetReportNamesToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#16365f',
    boxShadow: theme.shadows[1],
    fontSize: 15,
    fontFamily: "Poppins",
    fontWeight: 600,
    maxWidth: 500,
    padding: "16px 12px"
  },
}));

export default function VariableList(props) {
  const { state, UserDefinedState, templateCompany, connectionList } = props;
  return (
    <Fragment>
      <div className="users-container">
        <div className="add-btn">
          <div className="total-users">
            <span>
              Total <span>{UserDefinedState?.users?.length}</span>
            </span>
            <label>User Defined Variables</label>
          </div>

          <div className="add-new-link">
            <ASButton
              sx={{ paddingLeft: "15px", paddingRight: "15px" }}
              onClick={() => props.handleAddNewVariable()}
              rounded={true}
              primary={true}
            >
              <Link
                className="new-link"
              >
                <i className="plus-icon">+</i> ADD NEW
              </Link>
            </ASButton>
          </div>
        </div>
        {(UserDefinedState?.users?.length > 0) ?
        <Paper className="user-list-paper">
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableBody>                
                    <TableRow hover key={1} className="user-table-row">
                      <TableCell align="left"
                        sx={{ color: styles.secondaryColor, width: "155px"}}>
                          <Typography className="variable-list-header">
                            Variable Name
                          </Typography>
                      </TableCell>                     

                      <TableCell align="left"
                        sx={{ color: styles.secondaryColor, width: "100px", paddingLeft:"15px" }}>
                          <Typography className="variable-list-header">
                            Data Type
                          </Typography>
                      </TableCell>

                      <TableCell align="left"
                        sx={{ color: styles.secondaryColor, minWidth: "100px", padding: "0px", width: "230px"}}>
                          <Typography className="variable-list-header">
                            Default Value
                          </Typography>
                      </TableCell>

                      <TableCell align="left"
                        sx={{ color: styles.secondaryColor, width: "300px"}}>
                            <Typography className="variable-list-header" sx={{paddingLeft:"25px"}}>
                              Number of Collection
                            </Typography>                      
                      </TableCell>               
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
        </Paper>
        : null }

        <Paper className="user-list-paper">

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableBody>
                {UserDefinedState.users?.map((user, index) => {
                  return (
                    <TableRow hover key={index} className="user-table-row">
                      <TableCell
                        align="left"
                        sx={{ color: styles.secondaryColor, width: "300px" }}
                      >
                        {user?.name}
                      </TableCell>

                      <TableCell align="left" sx={{ color: styles.mutedColor, width: "200px" }}>
                        {user?.dataTypeName}
                      </TableCell>

                      <TableCell align="left" sx={{ color: styles.mutedColor, minWidth: "100px", padding: "0px", width: "400px" }}>
                        {user?.dataTypeName == "Dynamic List" ? (
                          <div className="show-list-div">
                            {(user?.complexTypeValue === null)
                              ? null
                              : (user?.complexTypeValue.DataCollectionUId === "") ?
                                <>{user?.complexTypeValue.DefaultValue}</>
                                : user?.showList && user?.listValues?.length > 0 ? 
                                <div className="variable-default-value-dropdown">
                                  <Stack spacing={2}>
                                    <Autocomplete
                                      size="small"
                                      id="variable-dropdown"
                                      freeSolo
                                      className="text-field"
                                      options={user?.listValues?.map((option) => ({
                                        key: option?.value,
                                        label: option?.key
                                      }))}
                                      renderOption={(props, option) => {
                                        return (
                                          <MenuItem
                                            {...props}
                                            key={`option ${option?.value}`}
                                            className={
                                              user?.defaultValue === option?.key
                                                ? "selected-dynamic-opt"
                                                : "menu-role-div"
                                            }
                                          >
                                            {option?.label}
                                          </MenuItem>
                                        );
                                      }}
                                      onChange={(event, option) => {
                                        props?.selectDefaultValue(option?.key, user)
                                      }}         
                                      value={user?.listValues.find((item)=>item?.value == user?.complexTypeValue?.DefaultValue)?.key}                                     
                                      PopperComponent={StyledAutocompletePopper}
                                      renderInput={(params) => (
                                        <ASTextField
                                          {...params}                                         
                                          label={"Dynamic List Values"}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: user?.defaultValue && params.InputProps.endAdornment,
                                        }}
                                        />
                                      )}
                                    />
                                  </Stack>                                  
                                  </div>
                                  :
                                  <Typography className="show-list"
                                    onClick={() => props?.onShowListClick(user)}>
                                    Show List
                                  </Typography>
                            }
                          </div>
                        ) : null}

                        {user?.dataTypeName == "List" ?
                          <div className="list-dropdown">
                            <ASTextField
                              size="small"
                              className="dropdown-list"
                              type={"text"}
                              fullWidth
                              value={user?.defaultUserDefinedListValue?.Label}
                              label="List Values"
                              variant="outlined"
                              select={true}
                              SelectProps={{
                                MenuProps: {
                                  className: "menu-role-div",
                                  sx: selectedMenuItemBackgroundColor,
                                },
                              }}
                            >
                              {user?.complexTypeValue?.map((list, index) => {
                                return (
                                  <MenuItem value={list?.Label} key={`dynamic ${index}`} >
                                    {list.Label}
                                  </MenuItem>
                                );
                              })}
                            </ASTextField>
                          </div>
                          : ""}
                        {user?.dataTypeName !== "Dynamic List" && user?.dataTypeName !== "List" ?
                        (user?.dataTypeName == "Boolean" ? 
                        <div className="boolean-box">
                          <div className="true=checkbox-div">
                                <Checkbox
                                    onBlur={(event)=>props.handleOutsideClick(user)}
                                    checked={JSON.parse(user?.primitiveTypeValue)} 
                                    onChange={(event) => props.primitiveTypeValueChangeHandler(user,event.target.checked)}
                                    sx={muiCheckboxStyle}
                                />
                                {`${user?.primitiveTypeValue}`}
                            </div>                            
                        </div>
                        : <div className="list-dropdown">
                           <ASTextField
                            size="small"
                            key={user?.name}
                            className="dropdown-list"
                            type={user?.dataTypeName == "Date" ? "date" : user?.dataTypeName =="Number" ? "number" : "text"}
                            fullWidth
                            variant="outlined"
                            value={user?.primitiveTypeValue}
                            onBlur={(event)=>props.handleOutsideClick(user)}
                            onChange={(event) => props.primitiveTypeValueChangeHandler(user,event.target.value)}
                          />
                        </div>)
                          : ""}
                      </TableCell>

                      <TableCell style={{ position: 'relative' }} align="left" sx={{ color: styles.mutedColor, width: "200px" }}
                        onMouseEnter={() => props.handleMouseEnter(index)}
                        onMouseLeave={props.handleMouseLeave}
                      >
                        {templateCompany ? "Count not available" : 
                        <ASheetReportNamesToolTip title={props.getUserDefineVariableReportsName(index).map((name, i) => (
                          <div key={i}>• {name}</div>
                        ))}
                          placement="right-start" open={props.isPopupVisible === index && user?.totalReport > 0}>
                          {( user?.totalReport ? user?.totalReport === 1 ? `${user?.totalReport} Collection` : `${user?.totalReport} Collections` : "No Collection" )}
                        </ASheetReportNamesToolTip>
                }
                      </TableCell>

                      <TableCell align="left" sx={{ color: styles.mutedColor, width: "200px" }}>
                        {(templateCompany && user?.templateConnectionId !== null ?
                          (connectionList?.find(list => list.id === user?.templateConnectionId)?.name || '')
                          : (!templateCompany && user?.templateConnectionId !== null ?
                            (connectionList?.find(list => list.templateConnectionId === user?.templateConnectionId)?.name || '')
                            : null))}
                      </TableCell>

                      <TableCell align="left" sx={{ color: styles.mutedColor, width: "50px" }}>
                        <MoreVertIcon
                          id={`${String(index)}+user-vertIcon`}
                          aria-controls={
                            UserDefinedState.userDefine
                              ? `${String(index)}+menu`
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            UserDefinedState.userDefine ? "true" : undefined
                          }
                          onClick={(event) =>
                            props.openUserMenuOption(event, true, index)
                          }
                          className="edit-user-icon"
                        >
                          Dashboard
                        </MoreVertIcon>

                        <Menu
                          id={`${String(index)}+menu`}
                          aria-labelledby={index}
                          anchorEl={
                            UserDefinedState.selectedRowIndex === index
                              ? UserDefinedState.anchorEl
                              : null
                          }
                          open={
                            UserDefinedState.selectedRowIndex === index
                              ? UserDefinedState.userDefine
                              : false
                          }
                          onClose={(event) => props.closeUserMenuOption(event)}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          className="edit-user-menu"
                        >
                          <MenuItem
                            disabled={!templateCompany && user?.templateConnectionId !== null
                              && user?.isTemplateVariable}
                            onClick={(event) =>
                              props.openConfirmationModal(event, index)
                            }
                          >
                            Delete Variable
                          </MenuItem>
                          <MenuItem
                            onClick={(event) =>
                              props.editVariable(event, user)
                            }
                          >
                            Edit Variable
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <MuiDialog
          isShowPopUp={props.confirmationModalOpen}
          secondaryButtonAction={props.closeConfirmationModal}
          primaryButtonAction={props.confirmDelete}
          closeDialog={props.closeConfirmationModal}
          secondaryButtonTitle="No"
          primaryButtonTitle="Yes"
          titleDescription="Are you sure you want to delete this variable?"
        />
        <div className="margintop20">
          <div className="add-btn">
            <div className="total-users">
              <span>
                Total <span>{state?.users?.length}</span>
              </span>
              <label>System Defined Variables</label>
            </div>
          </div>

          <Paper className="user-list-paper">
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableBody>
                  {state.users?.map((user, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => props.editUserPermission(user)}
                        key={index}
                        className="user-table-row"
                      >
                        <TableCell
                          align="left"
                          sx={{ color: styles.secondaryColor }}
                        >
                          {user?.displayName}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          {user?.defaultValue}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          {user?.name}
                        </TableCell>
                        {/* <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          <MoreVertIcon
                            id={`${String(index)}+user-vertIcon`}
                            aria-controls={
                              state.isUserMenuOpen
                                ? `${String(index)}+menu`
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              state.isUserMenuOpen ? "true" : undefined
                            }
                            onClick={(event) =>
                              props.openUserMenuOption(event, false, index)
                            }
                            className="edit-user-icon"
                          >
                            Dashboard
                          </MoreVertIcon>

                          <Menu
                            id={`${String(index)}+menu`}
                            aria-labelledby={index}
                            anchorEl={
                              state.selectedRowIndex === index
                                ? state.anchorEl
                                : null
                            }
                            open={
                              state.selectedRowIndex === index
                                ? state.isUserMenuOpen
                                : false
                            }
                            onClose={(event) =>
                              props.closeUserMenuOption(event)
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            className="edit-user-menu"
                          >
                            <MenuItem
                              onClick={() => props.editUserPermission(user)}
                            >
                              Edit Permission
                            </MenuItem>
                            <MenuItem
                              onClick={(event) =>
                                props.deleteSelectedUser(event, user)
                              }
                            >
                              Delete User
                            </MenuItem>
                          </Menu>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </Fragment>
  );
}
