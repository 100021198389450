import React, { useEffect,useState } from "react";
import TenantsMenu from "../../components/tenants-menu/tenants-menu";
import { useASContext } from "../context-api/as-context";
import { getToken, removeItem, setToken } from "../../shared/local-storage-handler/local-storage-handler";
import useAppTitle from "../../components/app-title/app-title";
import { AppEnum } from "../../constants/app-enum";
import { getRemainingBalance } from "../../services/license-service";
import { getAllUsers } from "../../services/user-service";
import { toast } from "react-toastify";
import jwt from 'jwt-decode'
import { setSessionStorageItem } from "../../shared/session-storage-handler/session-storage-handler";
import { getTemplateCompany } from "../../services/variable-service";
import { getSubscription } from "../../services/checkout-service";


const TenantsMenuContainer = (props) => {

    const { tenants, getUserName, tenantAnchorEl, closeTenantMenu, isMenuOpen, isIndividualUser,setSavedOrganization, refresh_token } = props;
    const [getTitle] = useAppTitle();
 
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [remainingTenantFeatureBalance, setRemainingTenantFeatureBalance] = useState(null);
    const [isOwner ,setIsOwner] = useState(false);
    const [ownerId ,setOwnerId] = useState("");

    useEffect(() => {
        getTitle() //calling hook to dynamically change the app title
    }, []);

    useEffect(() => {

        getRemainingBalance(AppEnum.FeatureType.COLLECTION_USERS_LIMIT).then((res) =>{
          if(res?.data?.featureCD != null){
            setRemainingTenantFeatureBalance(res.data.remainingFeatureUnit);
          }
        })
        getOwner();        
    }, []);

    const getOwner = async()=>{
        try {
            const response = await getAllUsers();
            if(response?.hasError){
                toast.error(response?.errorMessage);
            }
            else{
                filter(response?.data);                
            }
        } catch (error) {
            toast.error(error);
        }       
    }

    const filter =(data)=>{
        let userId = "";
        const thresholdDate = new Date(data[0]?.createdDateTime);
        data?.map((row) => {
            const rowDateTime = new Date(row?.createdDateTime);
            if (rowDateTime <= thresholdDate) {
                userId = row?.userId;
            }
        })
        setOwnerId(userId);
    }

    useEffect(()=>{
        const token = getToken();
        let decodedToken = jwt(token);
        if(ownerId === decodedToken?.sub){
            setIsOwner(true);
        }
        else{
            setIsOwner(false);
        }
    },[setOwnerId,ownerId])

    const asContext = useASContext();

    useEffect(() => {
        async function fetchTemplateCompany() {
            try {
                const response = await getTemplateCompany();
                if (response?.data == true) {
                    asContext?.templateCompany.setIsTemplateCompany(response?.data);
                }
            } catch (error) {
                console.error("Error fetching template company details:", error);
            }
        }
        fetchTemplateCompany();
    }, []);

    // //set whether subscription exists or not
    const subscriptionValidation = async() =>{
        try {
            const res = await getSubscription();            
            if (res && res.data) {
                let hasSubscipriton = res.data[0].asTenantUId !== null && res.data[0].noOfUsers > 0;
                asContext?.subscriptionValidation.setIsSubscriptionExist(hasSubscipriton);
                return hasSubscipriton;
            }
            return null;
        } catch (error) {
            console.error("Error fetching subscription details:", error);
        }
    }

    // it selects the choosen tenant which will be displayed everywhere in the app
    const selectTenant = async(tenant) => {

        asContext?.tenants?.setSelectedTenant(tenant);
        getUserName(tenant);
        closeTenantMenu();
        setToken("ASTenantId", tenant?.ASTenantId); //setting TenantId in localStorage
        setSessionStorageItem("ASTenantId", tenant?.ASTenantId)
        removeItem("DataCollectionListFilter");
        let isSubscriptionValid = await subscriptionValidation();
        const currentPath = window.location.pathname;
        if (currentPath.includes('user-permission')) {
            window.location.href = '/users';
        } else if(currentPath.includes('add-connection') && !isSubscriptionValid){
            window.location.href = '/connections';
        } else if(currentPath.includes('add-new-data-collection') && !isSubscriptionValid){
            window.location.href = '/data-collections';
        } else {
            window.location.reload();
        }
    }

    const routeToAddNewOrganization = (event) => {
        if(event)
            event.stopPropagation();

        // closeCollectionMenuOption(event);

        setIsDialogOpen(!isDialogOpen);
        // setCollectionUId(collection.uId);
    }

    const handleCloseDialog = () =>{
        // if(event)
        //      event.stopPropagation();
        setIsDialogOpen(!isDialogOpen);
    }

    return (
        <div>
            <TenantsMenu
                isOwner={isOwner}
                tenants={tenants}
                selectTenant={selectTenant}
                isMenuOpen={isMenuOpen}
                closeTenantMenu={closeTenantMenu}
                tenantAnchorEl={tenantAnchorEl}
                isDialogOpen={isDialogOpen}
                routeToAddNewOrganization={routeToAddNewOrganization}
                handleCloseDialog={handleCloseDialog}
                isIndividualUser={isIndividualUser}
                setSavedOrganization={setSavedOrganization}
                refresh_token={refresh_token}
                remainingTenantFeatureBalance={remainingTenantFeatureBalance}
            />
        </div>
    )
}

export default TenantsMenuContainer;