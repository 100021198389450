import * as collectionService from '../../../services/data-collection.service';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Parameters } from "../../../components/asheetPlugin/search-parameters/parameters";
import { testData } from '../../../services/test-data.service';
import { AppEnum } from '../../../constants/app-enum';
import { sortMethod } from '../../../shared/code-challanges/code-challanges';

export const SearchParameterController = ({ parameter, index, state, setState, setSearchOptionsParameters, searchOptionsParameters, isPopup }) => {

    const complexTypeValue = JSON.parse(parameter?.complexTypeValue);
    const [dynamicList, setDynamicList] = useState();
    const [multiSelectedFields, setMultiSelectedFields] = useState([]);
    const [optionValues, setOptionValues] = useState([]);
    const dateString = parameter?.isSystemDefineVariable ? parameter?.parameterValue : parameter?.defaultValue;
    const dateObject = new Date(dateString);


    useEffect(() => {
        let isDynamicListOptionsPresent = state?.dynamicListOptions && state.dynamicListOptions[complexTypeValue?.DataCollectionUId];

        if (parameter?.sysDataTypeId == AppEnum.DataTypeId.DynamicList && !isDynamicListOptionsPresent) {
            getCollectionDetail(complexTypeValue);
        }
        else if (parameter?.sysDataTypeId == AppEnum.DataTypeId.DynamicList && parameter.isMultiSelectList && isDynamicListOptionsPresent) {
                const options = state.dynamicListOptions[complexTypeValue?.DataCollectionUId];
                const paramWithDefaultValue = options?.filter((i) => i.value == complexTypeValue?.DefaultValue);
                handleMultiSelectParameterValues(options, paramWithDefaultValue);
        }
    }, [parameter])

    useEffect(() => {
        if (parameter.isMultiSelectList && complexTypeValue && parameter?.sysDataTypeId === AppEnum.DataTypeId.List) {

            let options = complexTypeValue.map(item => ({
                label: item.Label,
                value: item.Value,
                default: item.Default
            }));
            const paramWithDefaultValue  = options?.filter((i) => i.default);
            handleMultiSelectParameterValues(options, paramWithDefaultValue);
        }
    }, [])

    const getCollectionDetail = async (parsedComplexTypeValue) => {
        try {
            const response = await collectionService.getCollectionDetails(parsedComplexTypeValue?.DataCollectionUId , true);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else if (response?.data) {
                let testDataCollectionModal = {
                    connectionUId: response?.data?.connectionUId,
                    sysCollectionTypeId: response?.data?.sysCollectionTypeId,
                    sourceName: response?.data?.sourceName,
                    restRequestMethod: response?.data?.restRequestMethod,
                    restRequestIsAsync: response?.data?.restRequestIsAsync,
                    restBody: response?.data?.restBody,
                    restSendAsCD: response?.data?.restSendAsCD,
                    collectionParameters: response?.data?.collectionParameters,
                    collectionColumns: response?.data?.collectionColumns,
                    uId: response?.data?.uId,
                    returnFlatternData: response?.data?.sysCollectionTypeId === 7 ? true : false,
                    sourceNamePart4: response?.data?.sourceNamePart4,
                    IsASTenantUIdApplicable: false,
                    restDataTransformationScript: response?.data?.restDataTransformationScript
                }

                const collectionParameters = response?.data?.collectionParameters || [];
                collectionParameters.forEach(param => {
                    if (param.sysDataTypeId === 8) {
                        const complexTypeValue = JSON.parse(param.complexTypeValue);
                        if (!param.parameterValue){
                            param.parameterValue = complexTypeValue?.DefaultValue;
                        }
                    }
                });
                setState((prevState) => ({
                    ...prevState,
                    dynamicListCollectionModel: {
                      ...prevState.dynamicListCollectionModel,
                      [parsedComplexTypeValue?.DataCollectionUId]: testDataCollectionModal,
                    },
                  }));

                return getDynamicList(testDataCollectionModal, parsedComplexTypeValue, false);
            }
        } catch (error) {

        }
    }

    const getDynamicList = async (model, parsedComplexTypeValue, isForExecutedData) => {
        try {
            testData(model, null, getDynamicListOptions, parsedComplexTypeValue, null, null, null, isForExecutedData);
        } catch (error) {
            toast.error(error);
        }
    }

    const getDynamicListOptions = (data, parsedComplexTypeValue) => {
        const displayColumn = parsedComplexTypeValue?.DisplayColumn;
        const valueColumn = parsedComplexTypeValue?.ValueColumn;
        let options = [];

        if (!displayColumn || !valueColumn) {
            return;
        }

        if (data?.length === undefined) {
            var displayColumnList = data[displayColumn]?.filter(value => value !== null)
            var valueColumnList = data[valueColumn]?.filter(value => value !== null)

            options = displayColumnList.map((label, index) => ({
                label,
                value: valueColumnList[index]
            }));
        }
        else if (data?.length) {
            options = data?.map(item => ({
                label: item[displayColumn],
                value: item[valueColumn],
                isSelected: false
            }));
        } 

        if (!state?.dynamicListOptions) {
            options = options.map(option => {
                if (option.value == complexTypeValue?.DefaultValue) {
                    return {
                        ...option,
                        isSelected: true,
                    };
                }
                return option;
            });
        }
        if(options.length > 0){
            options = sortMethod(options, "label")
        }
        if (parameter.isMultiSelectList) {
            const paramWithDefaultValue = options?.filter((i) => i.value == complexTypeValue?.DefaultValue);
            handleMultiSelectParameterValues(options, paramWithDefaultValue);
        }

        handleSelectValueCascadedDropdown(parsedComplexTypeValue, options, null, true);
    };
    const handleOnInputChange = (e, index, isComplexTypeValue, isDynamicList, isUpdatingCascadedValues) => {

        let value = e?.target?.value;

        let prevParams = isPopup ? [...searchOptionsParameters] : [...state?.collectionParameters];
        if (index >= 0 && index < prevParams?.length) {

            if (isComplexTypeValue) {
                let complexTypeValue = JSON.parse(prevParams[index]?.complexTypeValue);
                let complexParamValue = null;

                if (!isDynamicList) {
                    complexParamValue = complexTypeValue?.find(i => i.Value == value)?.Value;
                }

                prevParams[index].parameterValue = isDynamicList ? value : complexParamValue;

                if (isDynamicList && state?.dynamicListCollectionModel && !isUpdatingCascadedValues) {
                    handleCascadingDropdowns(complexTypeValue?.DataCollectionUId, value);
                    handleSelectValueCascadedDropdown(complexTypeValue,  state?.dynamicListOptions?.[complexTypeValue?.DataCollectionUId], value, false);
                }
            }
            else {
                prevParams[index].parameterValue = value;
            }
        }

        if (isPopup) {
            setSearchOptionsParameters(prevParams);
        }
        else {
            setState((prevState) => { return { ...prevState, collectionParameters: prevParams } });
        }
    }

    const handleMultiSelectedFields = (e, value, index, isDefaultValue, isUpdatingCascadedValues) => {

        if (!isDefaultValue) {
            setMultiSelectedFields(value);
        }
        let prevParams = isPopup ? [...searchOptionsParameters] : [...state?.collectionParameters];

        if (prevParams[index]?.isMultiSelectList && prevParams[index]?.sysDataTypeId == AppEnum.DataTypeId.DynamicList && !isUpdatingCascadedValues) {
            handleSelectValueCascadedDropdown(complexTypeValue, state?.dynamicListOptions?.[complexTypeValue?.DataCollectionUId], value)
        }

        if (value && value?.length > 0) {
            const values = value?.map(item => item.value);

            if (index >= 0 && index < prevParams?.length) {
                prevParams[index].parameterValue = JSON.stringify(values);;
            }
        }
        else {
            if (index >= 0 && index < prevParams?.length) {
                prevParams[index].parameterValue = "";
            }
        }
        if (isPopup) {
            setSearchOptionsParameters(prevParams);
        }
        else {
            setState((prevState) => { return { ...prevState, collectionParameters: prevParams } });
        }
    }

    const handleMultiSelectParameterValues = (options, paramWithDefaultValue) => {
        let paramValues = [];
        if (parameter?.parameterValue) {
            try {
                // Attempt to parse the parameterValue
                paramValues = JSON.parse(parameter.parameterValue);
        
                // If parsing succeeds, ensure it is an array
                if (!Array.isArray(paramValues)) {
                    paramValues = [parameter.parameterValue];
                }
            } catch (e) {
                // If parsing fails, treat it as a single value in an array
                paramValues = [parameter.parameterValue];
            }
        }
        setOptionValues(options);

        const containsOnlyDefault = paramValues.length > 0 &&
                                    paramValues?.every(value =>
                                        paramWithDefaultValue?.some(option => option.value === value)
                                    );

        if (containsOnlyDefault) {
            setMultiSelectedFields(paramWithDefaultValue);
            handleMultiSelectedFields(null, paramWithDefaultValue, index, true)
        }
        else {
            const selectedOptions = options?.filter(option => paramValues?.includes(option.value))
                                .sort((a, b) => paramValues.indexOf(a.value) - paramValues.indexOf(b.value));
            handleMultiSelectedFields(null, selectedOptions, index, false);
        }
    }

    const  handleCascadingDropdowns = (parentDataCollectionUId, value)  => {
        const dynamicListModels = state?.dynamicListCollectionModel || {};
        Object.keys(dynamicListModels).forEach(key => {
            let isChildDropdownPresent = false
            if (key !== parentDataCollectionUId) {
                const modelData = dynamicListModels[key];
                const collectionParameters = modelData.collectionParameters || [];
    
                collectionParameters.forEach(param => {
                    if (param.sysDataTypeId == AppEnum.DataTypeId.DynamicList) {
                        try {
                            const complexTypeValue = JSON.parse(param.complexTypeValue);
                            if (complexTypeValue.DataCollectionUId === parentDataCollectionUId) {
                                param.parameterValue = value;
                                isChildDropdownPresent = true
                            }
                        } catch (e) {
                            console.error('Error parsing complexTypeValue:', e);
                        }
                    }
                });
                if (isChildDropdownPresent) {
                    for (const param of searchOptionsParameters) {
                        if (param.sysDataTypeId == AppEnum.DataTypeId.DynamicList) {
                            const complexTypeValue = JSON.parse(param.complexTypeValue);
                            if (complexTypeValue.DataCollectionUId === modelData?.uId) {
                                getDynamicList(modelData, complexTypeValue, true);
                                break;
                            }
                        }
                    }
                }
            }
        });
    };
    
    const handleSelectValueCascadedDropdown = (parsedComplexTypeValue, options, selectedValues, isSetParamValues) => {

        let isMultiSelectDropDownForCascading = false;
        let selectedParamIndex = null;
        for (const param of searchOptionsParameters) {
            if (param.sysDataTypeId === AppEnum.DataTypeId.DynamicList) {
                const complexTypeValue = JSON.parse(param.complexTypeValue);
                if (complexTypeValue.DataCollectionUId === parsedComplexTypeValue?.DataCollectionUId) {
                    selectedParamIndex = searchOptionsParameters?.indexOf(param);
                    if (param?.isMultiSelectList){
                        isMultiSelectDropDownForCascading = true;
                        break;
                    }
                }
            }
        }

        if (state?.dynamicListOptions?.[parsedComplexTypeValue?.DataCollectionUId]){
            let selectedOptions = [];
            selectedOptions = selectedValues ? selectedValues : state.dynamicListOptions[parsedComplexTypeValue?.DataCollectionUId].filter(option => option.isSelected)
            let isArrayValue = Array.isArray(selectedOptions)
            options = options.map(option => {
                const isSelectedOption = isArrayValue
                ? selectedOptions.some(selected => selected.value == option.value)
                : option.value == selectedValues;

                if (isSelectedOption) {
                    return {
                        ...option,
                        isSelected: true,
                    };
                }
                return {
                    ...option,
                    isSelected: false,
                };
            });

            if (isSetParamValues){
                if (isMultiSelectDropDownForCascading) {
                    handleMultiSelectedFields(null, options?.filter(o => o.isSelected), selectedParamIndex, false, true);
                }
                else {
                    let value = options?.filter(o => o.isSelected)?.length > 0 
                                ? options.filter(o => o.isSelected)[0]?.value 
                                : "";
                    const e = {
                        target: {
                            value: value
                        }
                    };
                    handleOnInputChange(e ,selectedParamIndex, true, true, true)
                }
            }
        }

        setState((prevState) => ({
            ...prevState,
            dynamicListOptions: {
              ...prevState.dynamicListOptions,
              [parsedComplexTypeValue?.DataCollectionUId]: options
            },
          }));
    }

    return (
        <Parameters
            parameter={parameter}
            index={index}
            dateObject={dateObject}
            complexTypeValue={complexTypeValue}
            dynamicList={dynamicList}
            handleOnInputChange={handleOnInputChange}
            handleMultiSelectedFields={handleMultiSelectedFields}
            multiSelectedFields={multiSelectedFields}
            optionValues={optionValues}
            state={state} />
    )
}